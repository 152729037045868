<!-- 招生订单记录--查看 -->
<template>
  <div style="min-height: 100%;background-color: #f7f7f7;min-width: 900rem;padding-bottom: 60rem">
    <div class="card">
      <el-descriptions v-for="item in labelConfig" title="" :column="3"
                       :labelStyle="{width: '110rem',justifyContent: 'flex-end',marginRight: '30rem'}"
                       style="margin-bottom: 30rem">
        <el-descriptions-item v-for="c_item in item" :label="c_item.label" content-class-name="my-content">{{ c_item.value }}</el-descriptions-item>
      </el-descriptions>
    </div>

    <el-button style="margin-top: 40rem;margin-right: 30rem" @click="$router.back();">返回</el-button>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  data() {
    return {
      labelConfig: []
    }
  },
  mounted() {
    this.$_register.get('/api/recruit/order/get-order-detail',{params:{order_id : this.$route.query.id}}).then(res => {
      let data = res.data.data
      this.labelConfig = [
        [
          {label: '商品订单号', value: data.order_number},
          {label: '订单类型', value: data.order_type},
          {label: '订单状态', value: data.pay_status},
        ],
        [
          {label: '购买商品名称', value: data.goods_pack_name},
        ],
        [
          {label: '报名学校', value: data.school_name},
          {label: '报名学校顺序号', value: data.school_num},
        ],
        [
          {label: '监护人姓名', value: data.customer_name},
          {label: '签约手机号', value: data.customer_phone},
          {label: '学生姓名', value: data.student_name},
        ],
        [
          {label: '实际支付金额', value: data.money_actual/100},
          {label: '支付方式', value: data.pay_mode},
        ],
        [
          {label: '合同名称', value: data.purchase_contract_name},
          {label: '合同编号', value: data.purchase_contract_num},
        ],
        [
          {label: '报名时间', value: data.sign_time},
          {label: '报名咨询老师', value: data.teacher_name},
        ],
        [
          {label: '支付订单号', value: data.pay_ids},
          {label: '支付成功时间', value: data.time_pay},
        ],
      ]
    })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.card {
  padding: 50rem 0 1rem 60rem;
  background: #fff;
}
</style>
